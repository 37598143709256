/**
 * @author johan.magnusson@svenskaspel.se (Johan Magnusson).
 */

import PropTypes from 'prop-types';
import '../../stylesheets/accordion.less';

/**
 * Vertical collapsing accordions.
 * An accordion is made up of a surrounded <Accordion> followed by any number of <AccordionItem>. Each <AccordionItem> must contain a <AccordionSummary> and a <AccordionDetails>.
 * Use controlled state if the desired pattern is to only allow one accordion item to be expanded at a time.
 */

const Accordion = ({
  branding,
  className,
  children,
  customer,
  isFlat,
  ...props
}) => {
  const uniqeId = Math.round(Math.random() * 100000 + 1000);
  const ref = React.useRef(null);
  const classNames = ['accordion', `accordion-customer-${customer}`];

  if (className) {
    classNames.push(className);
  }

  if (branding) {
    classNames.push(`accordion-${branding}`);
  } else {
    classNames.push(`accordion-default`);
  }

  const childrenWithEvent = React.Children.map(children, (child, index) =>
    React.cloneElement(child, {
      customer,
      id: child.props.id ? child.props.id : (index + uniqeId).toString(),
      isFlat,
      index
    })
  );

  return (
    <ul className={classNames.join(' ')} ref={ref} {...props}>
      {childrenWithEvent}
    </ul>
  );
};

Accordion.propTypes = {
  /**
   * Custom branding.
   * Built in support for default and inverted
   */
  branding: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf(['inverted'])
  ]),
  /**
   * AccordionItems
   */
  children: PropTypes.node,
  /**
   * Custom classes
   */
  className: PropTypes.string,
  /**
   * Neutral, tur or sport.
   */
  customer: PropTypes.oneOf(['sport', 'tur', 'neutral']),
  /**
   * Flat accordion - No borders. No shadow.
   */
  isFlat: PropTypes.bool
};

Accordion.defaultProps = {
  branding: 'default',
  customer: 'neutral',
  isFlat: false
};

export default Accordion;
